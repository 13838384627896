import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Image } from 'rebass';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "read-confirmations-for-confluence"
    }}>{`Read Confirmations for Confluence`}</h1>
    <br />
    <p>{`After installing Read Confirmations App, users will find the Action button at the top of the page above the page title.
Alternatively, you can use the "..." Menu to open the Read Confirmations popup.
Within the popup, you can decide to place a read confirmation button on the page - or send a request to users directly.
The latter will trigger a workbox or email notification, depending on your global settings.`}<br /></p>
    <Image width={'80%'} src='/public/Popup.png' sx={{
      m: 4,
      ml: 0
    }} mdxType="Image" />
    <p>{`If you decide to add the button the page, there will not be a notification sent to users - useful if you have other means
of notifying users about content (for example blog subscriptions).
You can get an overview of all your read confirmation requests in your user profile. You will see all your requests with their confirmation status, and if confirmed you will
be able to see when they were confirmed and for which page version.`}</p>
    <Image width={'80%'} src='/public/Profile.png' sx={{
      m: 4,
      ml: 0
    }} mdxType="Image" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      